import React from 'react';

const Hero = ({ title, subtitle, backgroundType, backgroundSrc }) => {
  return (
    <div className="hero">
      {backgroundType === 'video' ? (
        <video autoPlay loop muted playsInline className="hero-video">
          <source src={backgroundSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <img src={backgroundSrc} alt="Hero Background" className="hero-image" />
      )}
      <div className="hero-content">
        <h1>{title}</h1>
        <h2>{subtitle}</h2>
        {/*<div className='icon-scroll'></div> {/* Scroll icon placed here */}
      
        
        {/* Mailchimp form inserted here */}
        <div id="mc_embed_signup">
          <form action="https://edunow.us5.list-manage.com/subscribe/post?u=e29ed3bb20bf8029059c1d0cb&amp;id=e21420eda4&amp;f_id=006a8be9f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
            <div id="mc_embed_signup_scroll">
              <div className="mc-field-group">
                <label htmlFor="mce-FULLNAME">Full Name <span className="asterisk">*</span></label>
                <input type="text" name="FULLNAME" className="required text" id="mce-FULLNAME" required />
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-EMAIL">Email Address <span className="asterisk">*</span></label>
                <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" required />
              </div>
              <div hidden><input type="hidden" name="tags" value="4065529" /></div>
              <div id="mce-responses" className="clear foot">
                <div className="response" id="mce-error-response" style={{display: 'none'}}></div>
                <div className="response" id="mce-success-response" style={{display: 'none'}}></div>
              </div>
              <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
                <input type="text" name="b_e29ed3bb20bf8029059c1d0cb_e21420eda4" tabIndex="-1" />
              </div>
              <div className="clear foot">
                <input type="submit" value="Stay Updated" name="subscribe" id="mc-embedded-subscribe" className="button" />
              </div>
            </div>
          </form>
        </div>

      </div>
    </div>
  );
};

export default Hero;