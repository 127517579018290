// src/firebaseConfig.js

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBrg7Q0blukB7kYbEGMmek-hy00SujOCFU",
  authDomain: "edunow-learning.firebaseapp.com",
  projectId: "edunow-learning",
  storageBucket: "edunow-learning.appspot.com",
  messagingSenderId: "326096767374",
  appId: "1:326096767374:web:404b4815525aed77773cdf",
  measurementId: "G-VWQH529DJM"
};

const app = initializeApp(firebaseConfig);

// Initialize Analytics and get a reference to the service
const analytics = getAnalytics(app);

export default app;