// LandingPage.js
import React from 'react';
import Hero from '../components/Hero';


const LandingPage = () => (
  <Hero
  title="Prepare for the Future of Personalised Education"
  subtitle="Launching July 2024"
  backgroundType="video"
  backgroundSrc="./assets/videos/hero-bg.mp4"
  />
);

export default LandingPage;
